import React from 'react'
import classnames from 'classnames'
import lines from '../../images/newdesign/lines.svg'
import stars from '../../images/newdesign/stars.svg'

import * as css from './../../components/casestudy/caseStudyLayout.module.css'

const Blog = () => {
  return <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5', 'd-flex', 'flex-column', css.otherCaseStudy)}>
    <img src={lines} className="d-block" alt="Lines" width="225" height="83" loading="lazy" />
    <div className={classnames('row', 'align-items-center', 'g-5', 'pt-5', 'text-center')}>
      <h2 className="mt-0">What's new on our blog</h2>
    </div>
    <div className={classnames('row', 'align-items-center', 'g-5', 'py-5')}>
      <div className={classnames('me-4', 'mb-5', 'col')}>
        <a href='https://blog.getprobe.io/countering-fake-churn-from-past-due-payments'>
          Countering fake churn from past due payments
        </a>
      </div>
      <div className={classnames('me-4', 'mb-5', 'col')}>
        <a href='https://blog.getprobe.io/multiple-currencies-vs-saas-metrics-3-approaches'>
          Multiple currencies vs SaaS metrics: 3 approaches
        </a>
      </div>
      <div className={classnames('me-4', 'mb-5', 'col')}>
        <a href='https://blog.getprobe.io/basic-metrics-for-each-organization-in-your-saas-company'>
          Basic metrics for each organization in your SaaS company
        </a>
      </div>
    </div>
    <img src={stars} className="d-block" alt="Stars" width="60" height="75" loading="lazy" style={{alignSelf: 'flex-end'}}/>
  </div>
}

export default Blog
