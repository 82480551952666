import React from "react"
import classnames from "classnames"

import searchImage from '../../images/newdesign/search.svg'
import downloadImage from '../../images/newdesign/download.svg'
import graphImage from '../../images/newdesign/graph.svg'
import checkmarkImage from '../../images/newdesign/checkmark.svg'
import triangleImage from '../../images/newdesign/triangle.svg'
import halfcircleImage from '../../images/newdesign/halfcircle.svg'
import segmentImage from '../../images/newdesign/segment.svg'

import * as css from './ValueSection.module.css'

const Onboarding = () => {
  return <div className={css.blackBackground}>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'justify-content-start', 'align-items-center', 'g-5', 'pt-5')}>
        <h2>Assisted onboarding</h2>
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={searchImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Discovery</h3>
          <p>A discovery discussion to understand what metrics are important to you and where you store your data.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={downloadImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Data import</h3>
          <p>We save you time and take full responsibility for historical data import into Probe and ensure data quality.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={graphImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Monthly report</h3>
          <p>We review your account together to make sure you trust the numbers you see. We work to set up the best solution for data sync.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={checkmarkImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Ongoing success</h3>
          <p>We collect your feedback and automate ongoing data collection so it requires no further work.</p>
        </div>
      </div>
      <div className={classnames('row', 'justify-content-start', 'align-items-center', 'g-5', 'pt-5')}>
        <h2>Supporting your specific use case</h2>
      </div>
      <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={triangleImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Unstructured data</h3>
          <p>Calculate your saas metricts from unstructured data such as issued invoices.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={halfcircleImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Customer identity</h3>
          <p>Identify customer by other than default attributes, like VAT ID to make it easier on your operations.</p>
        </div>
        <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
          <img src={segmentImage} className="d-block" alt="Create icon" width="48" height="48" loading="lazy" />
          <h3>Data presentation</h3>
          <p>Group selected product names into one category or build flexible segmentations representing your business needs.</p>
        </div>
      </div>
    </div>
  </div>
}

export default Onboarding
