import React from 'react'
import classnames from 'classnames'
import heroImage from '../../images/newdesign/hero.png'

import * as css from '../casestudy/caseStudyLayout.module.css'

const Hero = () => {
  return <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
    <div className={classnames('row', 'flex-lg-row-reverse', 'align-items-center', 'g-5', 'py-3')}>
      <div className={classnames('col-10', 'col-sm-8', 'col-lg-6')}>
        <img src={heroImage} className="d-block mx-lg-auto img-fluid" alt="Probe Value Screen" width="700" height="545" loading="lazy" />
      </div>
      <div className={classnames('col-lg-6')}>
        <h1 className={classnames('display-5', 'fw-bold', 'lh-1', 'mb-3')}>Everything you need to understand and grow your revenue</h1>
        <p className='lead'>
        Probe is all-in-one analytical platform that gives you control and deep understanding of the financial performance of your company
        </p>
        <a className={classnames('btn', css.ctaFilled)} href="https://app.getprobe.io/#/sign_up" target='_blank' rel="noreferrer">Try Probe for free</a>
      </div>
    </div>
  </div>
}

export default Hero
