import React from 'react'
import { QuoteWithPhoto, CustomerPhoto } from '../casestudy/caseStudyLayout'
import classnames from 'classnames'

const CustomersQuotes = () => {
  return (
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div
        className={classnames(
          'row',
          'justify-content-center',
          'align-items-center',
          'g-5',
          'py-5'
        )}
      >
        <div
          id="carouselExampleControls"
          className={classnames('carousel', 'slide', 'carousel-dark')}
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div
            className={classnames(
              'carousel-inner',
              'row',
              'justify-content-center'
            )}
          >
            <div className={classnames('col-lg-8')}>
              <div className={classnames('carousel-item', 'active')}>
                <QuoteWithPhoto
                  text="Implementation was super smooth. I downloaded information in Excel spreadsheets and they were able to quickly manipulate the data into Probe."
                  customerPhoto={
                    <CustomerPhoto
                      name="Valerie Kirk"
                      title="Director of Business Operations"
                      company="Castle"
                      webPage="https://castle.io"
                    />
                  }
                />
              </div>
              <div className="carousel-item">
                <QuoteWithPhoto
                  text="With Probe every C-Level Manager is able to prepare their own data set to focus on what is relevant to their departments. ETA? In the blink of an eye."
                  customerPhoto={
                    <CustomerPhoto
                      name="Lukasz Chwistek"
                      title="Project & Operations Manager"
                      company="Edrone"
                      webPage="https://edrone.me/en/"
                    />
                  }
                />
              </div>
              <div className="carousel-item">
                <QuoteWithPhoto
                  text="Before implementing Probe, the process of getting our metrics took up to 15 days. Right now, we have results on first day of next month with zero manual work."
                  customerPhoto={
                    <CustomerPhoto
                      name="Aleksander Gorczyca"
                      title="Chief Financial Officer"
                      company="CallPage"
                      webPage="https://callpage.io"
                    />
                  }
                />
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomersQuotes
