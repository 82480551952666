import React from 'react'
import Layout from '../components/newdesign/layout'
import Seo from '../components/seo'
import Integrations from '../components/integrations'
import ScheduleDemoSection from '../components/ScheduleDemoSection'
import CustomersPromo from '../components/CustomersPromo'

import mainpageImg from '../images/mainpage-web.png'
import twitterImg from '../images/twitter-web.png'
import Hero from '../components/newdesign/hero'
import CustomersQuotes from '../components/newdesign/CustomersQuotes'
import Onboarding from '../components/newdesign/Onboarding'
import Casestudies from '../components/newdesign/Casestudies'
import Blog from '../components/newdesign/Blog'
import {CustomersTableUsecases, ReportingUsecases, DetailsUsecases} from '../components/newdesign/CustomersTableUsecase'

const title = () => process.env.GATSBY_APP_URL.replace(/^https:\/\//i, '')

const Web = () => (
  <Layout nakedBar trialCta slackApp pricing>
    <Seo
      title={title()}
      page="/"
      description="All-in-one analytical platform to gain deep understanding of the financial performance of your company"
      keywords={[
        `analytics`,
        `monthly business report`,
        `saas`,
        'report',
        'mrr',
        'churn',
        'collaborative reports',
        'collaborate',
        'saas metrics',
        'CFO',
      ]}
      twitterImg={twitterImg}
      mainpageImg={mainpageImg}
    />
    <Hero />
    <CustomersPromo />
    <CustomersTableUsecases />
    <ReportingUsecases />
    <DetailsUsecases />
    <Casestudies />
    <CustomersQuotes />
    <Onboarding />
    <Integrations />
    <ScheduleDemoSection />
    <Blog />
  </Layout>
)

export default Web
